@font-face {
    font-family: "GothamSSmMedium";
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('./fonts/GothamSSmMedium_normal_normal.woff2') format('woff2'), url('./fonts/GothamSSmMedium_normal_normal.woff') format('woff');
  }
  @font-face {
    font-family: "GothamSSmBook";
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('./fonts/GothamSSmBook_normal_normal.woff2') format('woff2'), url('./fonts/GothamSSmBook_normal_normal.woff') format('woff');
  }
  @font-face {
    font-family: "GothamSSmLight";
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('./fonts/GothamSSmLight_normal_normal.woff2') format('woff2'), url('./fonts/GothamSSmLight_normal_normal.woff') format('woff');
  }

div[aria-label="L1 badge"], div[aria-label="L2 badge"] {
    background: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjQxOTIxIDEuNzY1MzlDNC41MDY2MyAwLjA2OTM2MTcgNy41MDU4OSAwLjA3OTcxOCA5LjU5MDg5IDEuNzczNzhDMTIuMjU3NSAzLjk0MDQ0IDEyLjI2MTQgOC4wMzE0NiA5LjU3Mzk5IDEwLjE4MTRMOS4zMTIyMyAxMC4zOTA4TDguNjg3NTQgOS42MDk4OUw4Ljk0OTI5IDkuNDAwNDlDMTEuMTM0MyA3LjY1MjQ3IDExLjEzNjQgNC4zMTc5NCA4Ljk2MDI5IDIuNTQ5OUM3LjI0MDI4IDEuMTUyMzggNC43Njc0MSAxLjE0NTk1IDMuMDQ5ODEgMi41NDE1QzAuNzI4NDkxIDQuNDI3NTcgMC41NTQwOTcgNy45MTA3MiAyLjgxNTUyIDkuNzIzNzVDMi45MzY0NyA5LjgyMDcyIDMuMDU0NyA5LjkxMzU1IDMuMTY5NDggMTAuMDAxNUMzLjMyODQ0IDkuNTQyODEgMy41MDI3MSA5LjA4MjIyIDMuNjk0OTcgOC42MTI3N0MzLjk1MTI3IDcuOTg2OTMgNC4zOTczNSA3LjQ2Nzc2IDQuOTQ3NiA3LjEwMDkyQzYuMjY1MjIgNi4yMjI1MSA3Ljk5OTg4IDcuMjA3NTYgNy45OTk4OCA4Ljc1OTk4QzcuOTk5ODggOS45NTkxMSA3LjM2MDUgMTEuMzE0MyA1Ljk3OTY4IDExLjU3N0M1LjMzMTczIDExLjcwMDMgNC41NDM5NyAxMS43MjcyIDMuNzUzNDYgMTEuNDU4NkMzLjI3MDgyIDEzLjEwODEgMi45NTgzIDE0LjgyNTMgMi42ODM4IDE2Ljk3ODNMNS45OTk4OCAxMy4yNDc3TDkuMzAxODMgMTYuOTYyNEM5LjEwMTA4IDE1LjQ0NTcgOC44ODI1NyAxNC4wOTI2IDguNTE0ODEgMTIuNjIxNkw5LjQ4NDk2IDEyLjM3OTFDOS45OTMzNSAxNC40MTI2IDEwLjIyNDMgMTYuMjI0OSAxMC40OTYyIDE4LjQzOTRMMTAuNjkxNiAyMC4wMzExTDUuOTk5ODggMTQuNzUyOUwxLjMyOTM3IDIwLjAwNzNMMS41MDI5NCAxOC40NDUxQzEuODQwMjggMTUuNDA5MSAyLjE5NDEgMTMuMTcxOCAyLjg0NTIgMTEuMDA0M0MyLjc5NjU1IDEwLjk3MTEgMi43NDgxIDEwLjkzNjUgMi42OTk4OCAxMC45MDAzQzIuNTM0ODggMTAuNzc2NiAyLjM2NDE5IDEwLjY0MzYgMi4xOSAxMC41MDRDLTAuNjM5NTMxIDguMjM1NDYgLTAuMzIzNjc4IDMuOTkzOTkgMi40MTkyMSAxLjc2NTM5Wk00LjA1NDM1IDEwLjUwNEM0LjY0NTEzIDEwLjcxMDQgNS4yNTE4MSAxMC42OTc2IDUuNzkyNzcgMTAuNTk0NkM2LjUxMzY5IDEwLjQ1NzUgNi45OTk4OCA5LjcwMDI5IDYuOTk5ODggOC43NTk5OEM2Ljk5OTg4IDcuOTc1IDYuMTI5NTIgNy41MTQ4MyA1LjUwMjMgNy45MzI5N0M1LjEwNTM3IDguMTk3NTkgNC43OTU0OSA4LjU2NDE0IDQuNjIwMzcgOC45OTE3NUM0LjQxMDkxIDkuNTAzMjEgNC4yMjM0OSAxMC4wMDM4IDQuMDU0MzUgMTAuNTA0WiIgZmlsbD0iIzIxMTcxMCIvPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content-visibility: hidden;
    border: none;
    border-radius: 0%;
}

div[aria-label="L3 badge"] {
    background: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjczNjc5IDAuNDk5NzU2SDE0LjI2MzZMMTcuNjI2MyA1LjQwMzdMOC43NjE0IDE2LjA2OTNMNi4wMzMwMSA1LjU2NDk2TDUuOTk1ODkgNS41SDEuNzM2ODZMNS44OTIzIDEwLjc1Mkw1LjEwODA4IDExLjM3MjVMMC4zNzkzOTUgNS4zOTU5NkwzLjczNjc5IDAuNDk5NzU2Wk05LjAwMDEzIDEzLjAxMDZMNy4wNDkzMiA1LjVIMTAuOTUwOUw5LjAwMDEzIDEzLjAxMDZaTTEwLjA0NjkgMTIuOTU4MkwxNi4yNDU5IDUuNUgxMi4wMDQ0TDExLjk2NzIgNS41NjQ5OEwxMC4wNDY5IDEyLjk1ODJaTTEyLjU3NTggNC41SDE1Ljc5NDFMMTQuMDM4NyAxLjkzOTk5TDEyLjU3NTggNC41Wk0xMy4xMzg1IDEuNDk5NzZINC44NjE3OEw2LjU3NjIxIDQuNUgxMS40MjQxTDEzLjEzODUgMS40OTk3NlpNMi4yMDYyOSA0LjVINS40MjQ0NkwzLjk2MTY1IDEuOTQwMDlMMi4yMDYyOSA0LjVaIiBmaWxsPSIjMjExNzEwIi8+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    content-visibility: hidden;
    border: none;
    border-radius: 0%;
}


/* @font-face {
    font-display: swap;
    font-family: "Pandora Display";
    font-weight: 400;
    src: url("./fonts/PanDisplay-Regular.woff2") format("woff2"),url("./fonts/PanDisplay-Regular.woff") format("woff"),url("./fonts/PanDisplay-Regular.otf") format("otf");
    fallback: Arial, Helvetica, sans-serif;
}

@font-face {
    font-display: swap;
    font-family: "Pandora Text";
    font-weight: 400;
    src: url("./fonts/PanText-Regular.woff2") format("woff2"),url("./fonts/PanText-Regular.woff") format("woff"),url("./fonts/PanText-Regular.otf") format("otf");
    fallback: Arial, Helvetica, sans-serif;
}

@font-face {
    font-display: swap;
    font-family: "Pandora Display";
    font-weight: 700;
    src: url("./fonts/PanDisplay-Bold.woff2") format("woff2"),url("./fonts/PanDisplay-Bold.woff") format("woff"),url("./fonts/PanDisplay-Bold.otf") format("otf");
    fallback: Arial, Helvetica, sans-serif;
}

@font-face {
    font-display: swap;
    font-family: "Pandora Text";
    font-weight: 700;
    src: url("./fonts/PanText-Bold.woff2") format("woff2"),url("./fonts/PanText-Bold.woff") format("woff"),url("./fonts/PanText-Bold.otf") format("otf");
    fallback: Arial, Helvetica, sans-serif;
} */